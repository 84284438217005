<template>
  <div class="faq">
    <hero-alt />
    <base-section id="theme-features">
    <base-section-heading title="Preguntas Frecuentes">
      
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col>
          <div class="content">
              <v-expansion-panels focusable>
                <v-expansion-panel
                v-for="(item,i) in items"
                :key="i"
                >
                <v-expansion-panel-header class="text-align-left">
                    {{item.q}}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="text-align-left">
                    {{item.a}}
                </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
  </div>
</template>

<script>

export default {
    components: {
        heroAlt: () => import('./sections/HeroAlt')
    },
    metaInfo:{title:"FAQ"},
    name: "FAQ",
    data:()=>({
        items:[
            {q:"El GPS es visible en el automovil, con antenas u algún otro aditamento?",a:"Nuestras instalaciones son de lo mas discretas. El dispositivo si instala antenas pero estas son instaladas en lugares con dificil accesso y ocultos dentro del vehiculo."},
{q:"Que equipo es el que se instala?",a:"El equipo es el modelo TK103/2/a/b u algun otro equivalente."},
{q:"Yo tengo un TK103 instalado por mi mismo u otro agente, puedo registrarme y usar el monitoreo de Monitor Satelital?",a:"Si, ya que siendo que el sistema de monitoreo esta configurado para recibir las señles del dispositivo sin importar la quien haya realizado la instalacion previa."},
{q:"El servicio esta disponible en tuneles o ubicaciones techadas?",a:"El equipo envia datos usando la red celular, mientras el equipo reciba señal celular puede estar enviando registros a nuestro sistema."},
{q:"Que pasa si se desconecta la bateria del vehiculo?",a:"El dispositivo ya cuenta con una bateria interna con una duracion de 4-6 horas. Se puede instalar baterias adicionales para que el dispositivo este en funcionamiento mas tiempo sin necesidad la bateria del vehiculo."},
{q:"Cuanto tiempo el sistema guarda mi historial?",a:"Depende del plan contratado."},
{q:"Puedo inmovilizar mi vehículo desde cualquier computadora conectada a Internet?",a:"Si el equipo gps se encuentra instalado en un vehículo y su salida conectada a la ignición o la marcha, si es posible, lo único que recomendamos es antes de enviar el comando de inmovilización, verificar la posición del vehículo y la velocidad a la cual está siendo conducido para evitar un posible accidente."},
{q:"Puedo crear varios Usuarios para ver mis vehículos?",a:"Si, se pueden crear en la plataforma un número ilimitado de usuarios pero con acceso limitado."},
{q:"Cuantos dispositivos puedo tener en mi cuenta?",a:"Los dispositivos asociados a su cuenta pueden ser ilimitados."},

        ]
    })
}
</script>

<style>

</style>